@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-base-theme);
}

.close-btn {
  position: fixed;
  z-index: 1;
  top: var(--dialog-close-offset);
  right: var(--dialog-close-offset);
}

.dialog-content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: auto;
  max-width: 100%;
  padding: var(--grid-gutter);
}

.info-box {
  display: flex;
  align-items: stretch;
  width: 100%;
  max-width: 600px;
  border: 1px solid var(--color-border-theme);
  border-radius: var(--border-radius-lg);

  &__icon {
    align-self: center;
    width: auto;
    height: 100%;
    margin-left: -1px;
    padding: 3%;
    border-radius: var(--border-radius-lg);
    border: 0 solid var(--color-border-theme);

    .v-icon:before {
      font-size: 2em;
    }
  }
  &__text {
    align-self: center;
    text-align: center;
    flex-grow: 1;
    padding: 0.5rem 4% 0.5rem 0;
  }

  @media ($wx-sm-min) {
    &__icon {
      border-width: 1px;
      border-left-width: 0;

      .v-icon:before {
        font-size: 3em;
      }
    }
    &__text {
      padding-left: 5%;
    }
  }
}

.text-before-action {
  font-size: var(--font-size-h2);
  line-height: 1.2;
}
